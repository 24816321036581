import React from 'react';

const Home = () => {
    return (
        <div className="content d-flex justify-content-center align-items-center">
            <div className="d-block">
              
            </div>
        </div>
    )
};

export default Home;
