import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import Layout from "./layout";
import MainLayout from "./MainLayout";
import Logout from "./components/LoginPage/Logout";
import PageNotFound from "./components/PageNotFound";
import Home from "./components/Home";
import StandardWebDesign from "./components/Casino/standard-website-design";
import RestrictedTerritories from "./components/Casino/restricted-territories";
import GameProviders from "./components/Casino/game-providers";
import Introduction from "./components/Casino/introduction";
import AntiFraud from "./components/Casino/anti-fraud";
import AvailableLanguages from "./components/Casino/available-languages";
import APIEndpoint from "./components/Casino/API-endpoints-and-IPs";
import TopGames from "./components/Casino/top-games";
import CreateSession from "./components/Casino/create-session";
import Tvapi from "./components/Casino/tv-api";

class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter >
          <Routes element={<MainLayout />}>
          <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/standard-website-design" element={<StandardWebDesign />} />
          <Route path="/restricted-territories" element={<RestrictedTerritories />} />
          <Route path="/game-providers" element={<GameProviders />} />
          <Route path="/introduction-game-api" element={<Introduction />} />
          <Route path="/anti-fraud-check-request-requirements" element={<AntiFraud />} />
          <Route path="/top-games" element={<TopGames />} />
          <Route path="/available-languages" element={<AvailableLanguages />} />
          <Route path="/API-endpoints-and-IPs" element={<APIEndpoint />} />
          <Route path="/create-session" element={<CreateSession />} />
          <Route path="/tv-api" element={<Tvapi />} />

     </Route>
          <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path='*' element={<PageNotFound />} />
         </Routes>
        </BrowserRouter>
        </div>
        );
      }
}
export default App;
