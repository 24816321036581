import React from 'react';

const TopGames = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Top Games</h3>
<h4 id="TopGames-AirDice"><strong>AirDice</strong></h4>
<ul>
    <li>Midnight Wheel</li>
    <li>Midnight Blackjack</li>
    <li>Professor Archibald and the Catacombs of Easter Island</li>
    <li>Octane Overdrive</li>
    <li>Flash Freeze</li>
    <li>Farmer's Delight</li>
    <li>24k Golden Reels</li>
    <li>Mystery Reels</li>
    <li>Mayan Dice</li>
    <li>Joker Dice</li>
    <li>Tornado Dice</li>
    <li>Randomanic</li>
</ul>
<h4 id="TopGames-Amatic"><strong>Amatic</strong></h4>
<ol>
    <li><span >Book of Aztec</span></li>
    <li><span >Hot Fruits 100</span></li>
    <li><span >Book of Fortune</span></li>
    <li><span >Allways Fruits</span></li>
    <li><span >Billionaire</span></li>
    <li><span >Wild Shark</span></li>
    <li><span >Casanova</span></li>
    <li><span >Diamond Cats</span></li>
    <li><span >Arising Phoenix</span></li>
    <li><span >Lady Luck</span></li>
    <li><span >Hot Fruits 40</span></li>
    <li><span >Hot Fruits 20</span></li>
    <li><span >Enchanted Cleopatra</span></li>
    <li><span >Lucky Zodiac</span></li>
    <li><span >Eye of Ra</span></li>
</ol>


</div>
</div>
</div>
</section>  
            </div>
    )
};

export default TopGames;