import React from "react"
import { Link } from "react-router-dom";
import CustomLink  from "../CustomLink";

const SportsListItem = props => {
    return (<div id="documenter_sidebar">
    <div id="scrollholder" className="scrollholder">
    <div id="scroll" className="scroll">
    <ol id="documenter_nav">
    <li><CustomLink to="/introduction-game-api">Game API</CustomLink></li>   
    <li><CustomLink to="/standard-website-design">Standard Website Design</CustomLink></li>   
    <li><CustomLink to="/anti-fraud-check-request-requirements">Anti-Fraud Check Request Requirements</CustomLink></li>   
    <li>
    <a href="#"><i className="fa fa-angle-right"></i> GAME AGGREGATOR </a>
    <ol style={{display: "block"}}>
    <li><CustomLink to="/available-languages">Available Languages</CustomLink></li>
    <li><CustomLink to="/create-session">Create Session (API) - Player's details (user object)</CustomLink></li>
    <li><CustomLink to="/game-providers">Game providers and games</CustomLink></li>
    <li><CustomLink to="/restricted-territories">Restricted Territories</CustomLink></li>
    <li><CustomLink to="/API-endpoints-and-IPs">API Endpoints and IPs</CustomLink></li>
    <li><CustomLink to="/top-games">Top Games</CustomLink></li>
    </ol>
    </li>
    <li><CustomLink to="/tv-api">TV API</CustomLink></li>   

    </ol>
    </div>
    </div>
    </div>)
}

export default React.memo(SportsListItem)