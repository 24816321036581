import React, { Component } from 'react'

class Logout extends Component {
  constructor(props){
    super(props);  
  } 
  componentDidMount() { 
    localStorage.clear();
    window.location='/login';
} 
  render() { 
    return (
      <div> 
      </div>
    );
  }
}

export default Logout;
