import React from 'react';

const StandardWebDesign = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Standard Website Design</h3>
<p class="fw-bold tx-gray"> The website design will be responsive, with pages adapted to viewports: 1200px (and higher), 992px, 768px, 320px.</p>

<div class="table-wrap">
    <table class="table table-bordered">
        <tbody>
            <tr>
                <td class="confluenceTd">
                    <p align="center"><strong>No.</strong></p>
                </td>
                <td class="confluenceTd">
                    <p align="center"><strong>Page or Template Name</strong></p>
                </td>
                <td class="confluenceTd">
                    <p align="center"><strong>Comments / Limitations</strong></p>
                </td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">1</p></td>
                <td class="confluenceTd"><p>Main Page</p></td>
                <td class="confluenceTd">
                    <p>The page will be designed in two states: Logged Out state (ability to register or sign in) and Logged In state (link to Player Profile; Deposit button, etc.).</p>
                    <p>Up to 5 banners in the main banner carousel.</p>
                </td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">2</p></td>
                <td class="confluenceTd"><p>Login Page / Registration Page</p></td>
                <td class="confluenceTd"><p>Designed either as separate pages or as popups (recommended)</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">3</p></td>
                <td class="confluenceTd"><p>Game Lobby</p></td>
                <td class="confluenceTd"><p>Standard game thumbnails in either rectangle or square proportions</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">4</p></td>
                <td class="confluenceTd"><p>Game Page</p></td>
                <td class="confluenceTd"><p>Standard game page backgrounds</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">5</p></td>
                <td class="confluenceTd"><p>Content page template</p></td>
                <td class="confluenceTd"><p>Several levels of headings, bulleted and numbered lists, tables, hyperlinks, text styles</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">6</p></td>
                <td class="confluenceTd"><p>Promotions</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">7</p></td>
                <td class="confluenceTd"><p>Promotion Page (optional)</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">8</p></td>
                <td class="confluenceTd"><p>Tournaments</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">9</p></td>
                <td class="confluenceTd"><p>Tournament Page</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">10</p></td>
                <td class="confluenceTd"><p>Status Levels / VIP program</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">11</p></td>
                <td class="confluenceTd"><p>Affiliate Program (optional)</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">12</p></td>
                <td class="confluenceTd"><p>Payment Methods</p></td>
                <td class="confluenceTd"><p>Standard page UI/UX with minimal customization</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">13</p></td>
                <td class="confluenceTd"><p>FAQ</p></td>
                <td class="confluenceTd"><p>Standard page UI/UX with minimal customization</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">14</p></td>
                <td class="confluenceTd"><p>About Us (optional)</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">15</p></td>
                <td class="confluenceTd"><p>About Bitcoin (optional)</p></td>
                <td class="confluenceTd"></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">16</p></td>
                <td class="confluenceTd"><p>Email message template</p></td>
                <td class="confluenceTd"><p>1 template</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">17</p></td>
                <td class="confluenceTd"><p>Contact Us</p></td>
                <td class="confluenceTd"><p>Standard page UI/UX with customized styles</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">18</p></td>
                <td class="confluenceTd"><p>Reset password, confirm password, unlock account</p></td>
                <td class="confluenceTd"><p>Standard page UI/UX with customized styles</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">19</p></td>
                <td class="confluenceTd"><p>Player Profile customization</p></td>
                <td class="confluenceTd"><p>Standard Player Profile UI/UX with customized styles</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">20</p></td>
                <td class="confluenceTd"><p>Page 404</p></td>
                <td class="confluenceTd"><p>Standard page UI/UX with minimal customization</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">21</p></td>
                <td class="confluenceTd"><p>Website pre-loader</p></td>
                <td class="confluenceTd"><p>Non-interactive animation without JS programming</p></td>
            </tr>
            <tr>
                <td class="confluenceTd"><p align="center">22</p></td>
                <td class="confluenceTd"><p>Website favicon</p></td>
                <td class="confluenceTd"></td>
            </tr>
        </tbody>
    </table>
</div>
</div>
</div>
</div>
</section>  
            </div>
    )
};

export default StandardWebDesign;