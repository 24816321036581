import React from 'react';

const jsonrequest = {"user":"jaipur4",
"token":"ACCESS_TOKEN",
"partner_id":"PARTNER_ID",
"platform":"GPL_DESKTOP",  /// GPL_MOBILE
"req_url":"https://domain.com",
"lang":"en",
"ip":"CURRENT_IP_ADDRESS",
"game_id":"evolution:top_games",
"game_code":"evolution:top_games",
"id":"REFERENCE_USER_ID",
"balance":12010
}
const jsonresponse = {url:"GAME_PLAY_LINK"};
const CreateSession = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Create Session</h3>
<p>We are providing json file with all parameter to be used in creating session or playing games</p>



<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Create Session</span></p>

<strong>CLIENT_BASE_URL : https://api.casinoland.live/api/v1/front-end</strong>
<p><strong>CLIENT_API_URL : /play-game</strong></p>

<p><strong>Method:</strong> POST</p> 
<p><strong>Request</strong></p> 
<pre>
  <code> {JSON.stringify(jsonrequest)}
</code>
</pre>
<p><strong>Response</strong></p> 
<pre>
  <code> {JSON.stringify(jsonresponse)}
</code>
</pre>

<p><strong>Variable</strong></p> 
<table>
  <tr>
    <td>Deposit URL:</td>
    <td>YOUR DOMAIN NAME/casion-games</td>
  </tr>
  <tr>
    <td>Platform:</td>
    <td>GPL_DESKTOP for Desktop / GPL_MOBILE for Mobile</td>
  </tr>
  <tr>
    <td>IP:</td>
    <td>CURRENT IPV4 ADDRESS</td>
  </tr>
  <tr>
    <td>PARTNER_ID:</td>
    <td>Provided by casinoland</td>
  </tr>
</table>



<h3 style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Call Back</h3>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Get Balance</span></p>
<p><strong>Method:</strong> POST</p> 

<p><strong>Request</strong></p> 
<pre>
  <code> {('{"partner_id":"PARTNER_ID","userId":"user_id","timestamp":1657702676,"need_status_flag":1}')}
</code>
</pre>
<p><strong>Response</strong></p> 
<pre>
  <code> {('{bet_status:"Y",balance:USER_CURRENT_BALANCE}')}
</code>
</pre>


<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Credit</span></p>
<p><strong>Method:</strong> POST</p> 
<p><strong>Request</strong></p> 
<pre>
  <code> {('{"partnerKey": "PARTNER ID","user": {"id": "272727","currency": "INR"},"gameData": {"providerCode": "Casinoland","providerTransactionId": "7t_C656476496826295501","gameCode": "evolution:XXXTremeLightningRoulette","description": "win","providerRoundId": "7t-71ezz2-16fecbfb095d3ad5347c5ee3-qhci4bhfediwl45i"},"transactionData": {"id": "643045","amount": 2000,"referenceId": "643045"},"timestamp": "1662512309"}')}
</code>
</pre>
<p><strong>Response</strong></p> 
<pre>
  <code> {('{bet_status:"Y",balance:USER_CURRENT_BALANCE}')}
</code>
</pre>

<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Debit</span></p>
<p><strong>Method:</strong>POST</p> 
<p><strong>Request</strong></p> 
<pre>
  <code> {('{"partnerKey": "PARTNER ID","user": {"id": "272727","currency": "INR"},"gameData": {"providerCode": "Casinoland","providerTransactionId": "7t_C656476496826295501","gameCode": "evolution:XXXTremeLightningRoulette","description": "bet","providerRoundId": "7t-71ezz2-16fecbfb095d3ad5347c5ee3-qhci4bhfediwl45i"},"transactionData": {"id": "643045","amount": 2000,"referenceId": "643045"},"timestamp": "1662512309"}')}</code>
</pre>
<p><strong>Response</strong></p> 
<pre>
  <code> {('{bet_status:"Y",balance:USER_CURRENT_BALANCE}')}
</code>
</pre>

</div>
</div>
</div>
</section>  
            </div>
    )
};

export default CreateSession;