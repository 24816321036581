import React from 'react';

const RestrictedTerritories = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Restricted Territories</h3>

<div class="table-wrap" style={{overflowX:"scroll"}}>
    <table class="table table-bordered confluenceTable">
        <tbody>
            <tr>
                <td class="confluenceTd">
                    <p><strong>PROVIDER</strong></p>
                </td>
                <td class="confluenceTd">
                    <p><strong>RESTRICTED COUNTRIES</strong></p>
                </td>
            </tr>
            <tr>
                <td class="highlight-yellow confluenceTd" data-highlight-colour="yellow"><h4 title="" id="RestrictedTerritories-NetEnt">NetEnt</h4></td>
                <td class="highlight-yellow confluenceTd" data-highlight-colour="yellow">
                    <p title=""><strong>A. Blacklisted Territories:</strong></p>
                    <p title="">
                        Afghanistan, Albania, Algeria, Angola, Australia, Bahamas, Botswana, Cambodia, Ecuador, Ethiopia, Gergia, Ghana, Guyana, Hong Kong, Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia, Nicaragua, North Korea,
                        Pakistan, Panama, Papua New Guinea, Philippines, Sri Lanka, Singapore, Sudan, Syria, Sweden Taiwan, Trinidad and Tobago, Tunisia, Ukraine, Uganda, Yemen, Zimbabwe, 2 regions in Canada (
                        <span style={{color:" rgb(0, 0, 0)"}}>Quebec, British Columbia, Manitoba, Ontario, Alberta (feb 2022)</span>, Ukraine, Venezuela, Hong Kong
                    </p>
                    <p title=""><strong>B. Regulated Territories:</strong></p>
                    <p title=""><em>Only permitted if receipt of a license from local regulator has been seen and approved by NetEnt</em></p>
                    <p title="">
                        Belgium, Bulgaria, <span>Czech Republic,</span> Denmark, Estonia, France, Germany, Italy, <span>Latvia, Lithuania,</span> Mexico, Portugal, Romania, Serbia, Spain, Sweden, Switzerland, United States of America,
                        United Kingdom, Ukraine (24.02.2021), Georgia (01.03.2021), <em><span style={{color:" rgb(0, 0, 0)"}}>South Africa (from 24.11.2021)</span></em>
                    </p>
                    <p title=""><strong>C. </strong><strong>Restrictions for Branded Games:</strong></p>
                    <p title="">
                        <span style={{color:" rgb(0, 0, 255)"}}>
                            <em><strong>Jumanji, emojiplanet, Netent Rocks (Guns N'Roses, Jimi Hendrix, Motorhead, Ozzy Osbourne )</strong></em>
                        </span>
                    </p>
                    <p title="">
                        Territories from <strong>A</strong> and <strong>B</strong>, <strong><u>plus</u></strong>: China
                    </p>
                    <p title=""><br /></p>
                    <p title="">
                        <em>
                            <strong><span style={{color:" rgb(0, 0, 255)"}}>1. in addition to </span></strong>
                        </em>
                        <strong>
                            <span style={{color:" rgb(0, 0, 255)"}}><span style={{color:" rgb(0, 0, 0)"}}>A, B</span></span>
                        </strong>
                        <em>
                            <strong><span style={{color:" rgb(0, 0, 255)"}}> sections </span></strong><span style={{color:" rgb(0, 0, 255)"}}>for </span><strong><span style={{color:" rgb(0, 0, 255)"}}>Planet of the Apes:</span></strong>
                        </em>
                    </p>
                    <p title="">Azerbaijan, China, India, Malaysia, Qatar, Thailand, Turkey, Ukraine, Russia</p>
                    <p title=""><br /></p>
                    <p title="">
                        <em>
                            <strong><span style={{color:" rgb(0, 0, 255)"}}>2. in addition to </span></strong>
                        </em>
                        <span style={{color:" rgb(0, 0, 0)"}}><strong>A, B</strong></span>
                        <em>
                            <span style={{color:" rgb(0, 0, 255)"}}><strong> sections </strong>for <strong>Vikings</strong></span><strong><span style={{color:" rgb(0, 0, 255)"}}>: </span></strong>
                        </em>
                    </p>
                    <p title="">
                        Afghanistan, Albania, Algeria, Angola, Australia,
                        <span style={{color:" rgb(0, 0, 0)"}}>
                            Azerbaijan, Cambodia, Canada, China, Ecuador, France, Guyana, Hong Kong, India, Indonesia, Iran, Iraq, Israel, Kuwait, Laos, Malaysia, Myanmar, Namibia, North Korea, Pakistan,
                        </span>
                        Papua New Guinea, Philippines, Qatar, Russia, Singapore, South Korea, Sudan, Syria, Taiwan, Thailand, Tunisia, Turkey, Ukraine, United States of America, Uganda
                    </p>
                    <p title="">
                        <span style={{color:" rgb(0, 0, 255)"}}>
                            <em><strong>3. in addition to </strong></em><span style={{color:" rgb(0, 0, 0)"}}><strong>A, B</strong></span><em><strong> sections </strong>for <strong>Narcos:</strong></em>
                        </span>
                    </p>
                    <p title="">China, Indonesia, South Korea</p>
                    <p title="">
                        <span style={{color:" rgb(0, 0, 255)"}}>
                            <em>
                                <strong>4. Universal monsters studios (Creature from the Black Lagoon, Dracula, The Invisible Man, The Phantom's Curse) </strong>are only permitted for the following territories (but still prohibited if
                                country is listed in
                            </em>
                            <strong>A and B sections)</strong><em><u>:</u></em>
                        </span>
                    </p>
                    <p title="">
                        Andorra, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Brazil, Georgia, Iceland, Liechtenstein, Moldova, Monaco, Montenegro, Norway, Russia, San Marino, Serbia, Switzerland,<br />
                        Croatia, Macedonia, Austria, Belgium, Bulgaria, Cyprus, Czech Republic, Denmark, Estonia, Finland, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta,<br />
                        Netherlands, Peru, Poland, Portugal, Romania, Russia, Slovakia, Slovenia, Spain, Sweden and the United Kingdom.
                    </p>
                    <p title="">The United States, its military bases, territories and possessions are specifically excluded from the Territory.</p>
                    <p title="">
                        <span style={{color:" rgb(0, 0, 255)"}}>
                            <em><strong>5. Conan </strong></em>
                        </span>
                    </p>
                    <p title="">
                        Territories from <strong>A</strong> and <strong>B</strong>, <strong><u>plus</u></strong>: China
                    </p>
                    <p title=""><br /></p>
                    <p title="">
                        <strong><em>6. in addition to A, B sections for Street Fighter II: </em></strong>
                    </p>
                    <p title="">
                        <strong>
                            <em>
                                1. Anguilla<br />
                                2. Antigua &amp; Barbuda<br />
                                3. Argentina<br />
                                4. Aruba<br />
                                5. Barbados<br />
                                6. Bahamas<br />
                                7. Belize<br />
                                8. Bermuda<br />
                                9. Bolivia<br />
                                10. Bonaire<br />
                                11. Brazil<br />
                                12. British Virgin Islands<br />
                                13. Canada<br />
                                14. Cayman Islands<br />
                                15. China<br />
                                16. Chile<br />
                                17. Clipperton Island<br />
                                18. Columbia<br />
                                19. Costa Rica<br />
                                20. Cuba<br />
                                21. Curacao<br />
                                22. Dominica<br />
                                23. Dominica Republic<br />
                                24. El Salvador<br />
                                25. Greenland<br />
                                26. Grenada<br />
                                27. Guadeloupe<br />
                                28. Guatemala<br />
                                29. Guyana<br />
                                30. Haiti<br />
                                31. Honduras<br />
                                32. Jamaica<br />
                                33. Japan<br />
                                34. Martinique<br />
                                35. Mexico<br />
                                36. Montserrat<br />
                                37. Navassa Island<br />
                                38. Paraguay<br />
                                39. Peru<br />
                                40. Puerto Rico<br />
                                41. Saba<br />
                                42. Saint Barthelemy<br />
                                43. Saint Kitts and Nevis<br />
                                44. Saint Lucia<br />
                                45. Saint Martin<br />
                                46. Saint Pierre and Miquelon<br />
                                47. Saint Vincent and the Grenadines<br />
                                48. Sint Eustatius<br />
                                49. Sint Maarten<br />
                                50. Suriname<br />
                                51. Turks and Caicos Islands<br />
                                52. United States of America<br />
                                53. Uruguay<br />
                                54. US Virgin Islands<br />
                                55. Venezuela
                            </em>
                        </strong>
                    </p>
                    <p title=""><br /></p>
                    <p title=""><br /></p>
                    <p title="">
                        <strong><em>7. in addition to A, B sections for Fashion TV: </em></strong>Cuba, Jordan, Turkey, Saudi Arabia
                    </p>
                    <p title=""><strong>D. Restrictions for Pooled Jackpot Games </strong></p>
                    <p title="">
                        <span style={{color:" rgb(0, 0, 255)"}}>
                            <strong><em>Mega Fortune, Arabian Nights</em></strong>
                        </span>
                    </p>
                    <p title="">
                        Territories from <strong>A</strong> and <strong>B</strong>, <strong><u>plus</u></strong>: Azerbaijan, China, Curacao, Denmark, India, Italy, Japan, Malaysia, Qatar, Russia, Spain, Thailand, Turkey, United Arab
                        Emirates, Ukraine
                    </p>
                    <p title="">
                        <em><strong>Note</strong>: Pooled Jackpot games are only available for SoftSwiss White Labels holding MGA license.</em>
                    </p>
                </td>
            </tr>
            <tr>
                <td class="confluenceTd"><h4 id="RestrictedTerritories-1x2Gaming">1x2Gaming</h4></td>
                <td class="confluenceTd">
                    <ul>
                        <li>Afghanistan</li>
                        <li>Algeria</li>
                        <li>Canada</li>
                        <li>Cuba</li>
                        <li>Egypt</li>
                        <li>Haiti</li>
                        <li>Iran</li>
                        <li>Iraq</li>
                        <li>Laos</li>
                        <li>Libya</li>
                        <li>North Korea</li>
                        <li>Pakistan</li>
                        <li>Palestinian territory occupied</li>
                        <li>Philippines</li>
                        <li>Somalia</li>
                        <li>Syria</li>
                        <li>France</li>
                        <li>Israel</li>
                        <li>United States of America</li>
                        <li>Australia</li>
                        <li>Italy</li>
                        <li>Sweden</li>
                        <li>United Kingdom</li>
                        <li>Netherlands</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td class="confluenceTd">1spin4win</td>
                <td class="confluenceTd">none</td>
            </tr>
            <tr>
                <td class="confluenceTd"><h4 id="RestrictedTerritories-5men">5men</h4></td>
                <td class="confluenceTd"><span style={{color:" rgb(33, 37, 41)"}}>Armenia, Iran, North Korea, Pakistan, United States</span></td>
            </tr>
            <tr>
                <td class="confluenceTd"><h4 id="RestrictedTerritories-AirDice">AirDice</h4></td>
                <td class="confluenceTd"><span style={{color:" rgb(33, 37, 41)"}}>Finland, United Kingdom, France</span></td>
            </tr>
            <tr>
                <td class="confluenceTd"><h4 id="RestrictedTerritories-AbsoluteLiveGaming(7mojos)">Absolute Live Gaming (7mojos)</h4></td>
                <td class="confluenceTd">
                    <ol>
                        <li>
                            <p>For all providers: USA, Australia, France</p>
                            <p>For ALG: +Spain</p>
                            <p>For 7Mojos: +Bulgaria</p>
                        </li>
                    </ol>
                </td>
            </tr>
       
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Amatic">Amatic</h4></td>
    <td class="confluenceTd">
        <p>
            1. Afghanistan<br />
            2. Albania<br />
            3. Algeria<br />
            4. Angola<br />
            5. Antigua and Barbuda<br />
            6. Armenia<br />
            7. Australia<br />
            8. Austria<br />
            9. Cambodia<br />
            10. China<br />
            11. Cuba<br />
            12. Cyprus<br />
            13. Ecuador<br />
            14. Estonia<br />
            15. French Polynesia<br />
            16. France and Territories<br />
            17. Georgia<br />
            18. Greece<br />
            19. Guyana<br />
            20. Hong Kong<br />
            21. Indonesia<br />
            22. Iran<br />
            23. Iraq<br />
            24. Israel<br />
            25. Kuwait<br />
            26. Lao<br />
            27. Libya<br />
            28. Liechtenstein<br />
            29. Macao<br />
            30. Myanmar<br />
            31. Namibia<br />
            32. Netherlands Antilles<br />
            33. Nicaragua<br />
            34. North Korea<br />
            35. Pakistan<br />
            36. Panama<br />
            37. Papua New Guinea<br />
            38. Philippines<br />
            39. Singapore<br />
            40. Slovakia<br />
            41. South Africa<br />
            42. South Korea<br />
            43. Sudan<br />
            44. Switzerland<br />
            45. Syria<br />
            46. Taiwan<br />
            47. Tunisia<br />
            48. Uganda<br />
            49. United States of America and Territories<br />
            50. Vietnam<br />
            51. Yemen<br />
            52. Zimbabwe
        </p>
        <p><span> </span></p>
        <p>
            <br />
            <br />
        </p>
        <p><span style={{color:" rgb(31, 73, 125)"}}>Amatic has an exclusive partnership for Armenia, Tunisia and will therefore not provide games to any other operators in that country</span></p>
    </td>
</tr>
<tr>
    <td class="confluenceTd">
        <h4 id="RestrictedTerritories-ATMOSFERALiveSlots">
            ATMOSFERA <br />
            LiveSlots
        </h4>
    </td>
    <td class="confluenceTd">
        <p>
            <strong>ATMOS</strong><strong style={{letterSpacing:"0px"}}>FERA: </strong>
            <span style={{letterSpacing:"0px"}}>Afganistan, Antiqua and Barbuda, Cuba, Iran, Iraq, Israel, Kahnawake, Libya, Macau, Netherlands Antilles, Republic of Serbia, Sudan, Syria, Canada, The USA and its territories </span>
            <span style={{letterSpacing:"0px"}}>and any other jurisdictions or territories which may be added and designated as Excluded Territories upon the Parties’ mutual prior written consent, from time to time.</span>
        </p>
        <p>
            <strong>LiveSlots</strong>: Afganistan, Antiqua and Barbuda, Cuba, Iran, Iraq, Israel, Kahnawake, Libya, Macau, Netherlands Antilles, Republic of Serbia, Sudan, Syria, Canada, The USA and its territories, Republic of Armenia and
            any other jurisdictions or territories which may be added and designated as Excluded Territories upon the Parties’ mutual prior written consent, from time to time.
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-AsiaGaming">Asia Gaming</h4></td>
    <td class="confluenceTd">
        <p>
            Taiwan – TW<br />
            Hong Kong – HK, <br />
            Macao – MO<br />
            the United States – US<br />
            the Philippines – PH <br />
            Singapore – SG
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-AuthenticGaming">Authentic Gaming</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>Germany</p>
            <p><br /></p>
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Belatra">Belatra</h4></td>
    <td class="confluenceTd">None</td>
</tr>
<tr>
    <td class="confluenceTd">Betsoft</td>
    <td class="confluenceTd">Curacao - None. MGA - USA</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-BoomingGames">Booming Games</h4></td>
    <td class="confluenceTd">
        <ul>
            <li>Australia - AU - <strong>There are no technical restrictions</strong></li>
            <li>Belgium (other than with license) - BE</li>
            <li>Bulgaria (other than with license) - BG</li>
            <li>Cayman Islands - KY</li>
            <li>Czech Republic (other than with license) - CZ</li>
            <li>Columbia (other than with license) - CO</li>
            <li>Denmark (other than with license) - DK</li>
            <li>Estonia (other than with license) - EE</li>
            <li>Iran - IR</li>
            <li>Iraq - IQ</li>
            <li>Israel - IL</li>
            <li>Italy (other than with license) - IT</li>
            <li>Latvia (other than with license) - LV</li>
            <li>Lithuania (other than with license) - LT</li>
            <li>North Korea - KP</li>
            <li>Portugal (other than with license) - PT</li>
            <li>Romania (other than with license) - RO</li>
            <li>Saudi Arabia - SA</li>
            <li>Singapore - SG</li>
            <li>Slovakia (other than with license) - SK</li>
            <li>Spain (other than with license) - ES</li>
            <li>United Kingdom (other than with license) - GB</li>
            <li>Ukraine - UK (has its own license)</li>
            <li>
                United States of America, and all territories and possessions, being the U.S. Virgin Islands, U.S. Minor Outlying Islands, Guam, Puerto Rico, Marianas Islands and American Samoa. (
                <span>US VI UM GU PR MP AS ) - <strong>There are no technical restrictions</strong></span>
            </li>
            <li>
                Vatican City
                <span>
                    - VA<br />
                    Sweden<strong> </strong>- SE (other than with license)
                </span>
            </li>
        </ul>
        <pre>In fact only the United Kingdom is technically blocked.<br /><br />Curacao (the countries that are actually blocked) - <span style={{color:"rgb(61,60,64)"}}>United Kingdom, Israel, Iraq, Iran, Cayman Islands, Saudi Arabia, Singapore</span> </pre>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-CasinoTechnology">Casino Technology</h4></td>
    <td class="confluenceTd">
        <p>
            United States of America and Territories<br />
            China<br />
            Kazakhstan<br />
            North Korea<br />
            Cyprus<br />
            Vietnam<br />
            Thailand<br />
            Japan<br />
            Turkey<br />
            Singapore<br />
            Hong Kong<br />
            Canada<br />
            Malaysia<br />
            Indonesia<br />
            Israel
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-EGT">EGT</h4></td>
    <td class="confluenceTd">
        <p>
            American Samoa <br />
            Australia <br />
            Belgium <br />
            Bulgaria <br />
            Canada<br />
            Czechia <br />
            Denmark <br />
            Estonia <br />
            France <br />
            United Kingdom <br />
            Georgia <br />
            Guam <br />
            Lithuania <br />
            Latvia <br />
            Northern Mariana Islands <br />
            Netherlands <br />
            Nigeria<br />
            Puerto Rico <br />
            Romania <br />
            United States Minor Outlying Islands <br />
            United States <br />
            Virgin Islands U.S.
        </p>
        <p>(All territories except those where the local jurisdiction prohibits online gambling as well as territories with local regulations)</p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-ELK">ELK</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>
                <span style={{color:" rgb(26, 26, 26)"}}>Australia, </span><span style={{color:" rgb(26, 26, 26)"}}>China, </span><span style={{color:" rgb(26, 26, 26)"}}>France, </span><span style={{color:" rgb(26, 26, 26)"}}>United States, </span>
                <span style={{color:" rgb(26, 26, 26)"}}>Iran, Russia</span>
            </p>
            <p>
                <span style={{color:" rgb(26, 26, 26)"}}>
                    The resticted areas are update Feb 3, 2022. Please see
                   
                </span>
            </p>
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Endorphina">Endorphina</h4></td>
    <td class="confluenceTd">
        <p>
            FR - France<br />
            AU - Australia
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-EvolutionGaming">Evolution Gaming</h4></td>
    <td class="confluenceTd">
        <p>Australia - AU</p>
        <p>USA – US</p>
        <p>Latvia – LV</p>
        <p>South Africa</p>
        <p>Sweden</p>
        <p>Canada - British Colombia, Canada - Monitoba, Canada - Quebec, Canada - Ontario, Canada - <span style={{color:" rgb(0, 0, 0)"}}>Alberta (feb 2022)</span></p>
        <p>Ukraine</p>
        <p>South Africa (from 24.11.2021)</p>
        <p>Syria</p>
        <p>Sudan</p>
        <p>Singapore</p>
        <p>Hong Kong</p>
        <p>Iran</p>
        <p>North Korea</p>
        <p>Venezuela</p>
        <p><strong>Games</strong>:</p>
        <p>
            Deal or No Deal -Turkey<br />
            Monopoly - Turkey, Germany, Austria
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd">
        <h4 id="RestrictedTerritories-EpicMedia(Merkur,Blueprint,AugustGaming,Betradar)">Epic Media (<span style={{color:" rgb(61, 60, 64)"}}>Merkur, Blueprint, August Gaming,</span><span style={{color:" rgb(61, 60, 64)"}}> Betradar</span>)</h4>
    </td>
    <td class="confluenceTd">
        <p>
            <span style={{color:" rgb(61, 60, 64)"}}>
                For all - U.S.A, Iran, North Korea, Cuba, Sudan, Syria, Singapore, Taiwan, Algeria, Malta, Myanmar, Afghanistan, Angola, Ecuador, Guyana, Iraq, Panama, Papua New Guinea, Uganda, Vanuatu, Yemen;
                <span style={{color:" rgb(31, 35, 38)"}}>United Kingdom, Estonia, Colombia, Croatia, Latvia, Lithuania, Czech Republic, Portugal; Hong Kong, Philippines</span>
            </span>
        </p>
        <p>
            Blueprint, Merkur - Austria, Bulgaria, Germany, Australia, Sweden, <span style={{color:" rgb(31, 35, 38)"}}>Italy, Israel, Denmark, Spain, Romania, Hungary, Canada, <span style={{color:" rgb(50, 53, 55)"}}>Cyprus</span><br /></span>
        </p>
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}>August Gaming: <span style={{color:" rgb(0, 0, 0)"}}>Curacao, The Netherlands, France, United States of America, Australia, Singapore</span></span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-EveryMatrix(Spearhead)">Every Matrix (Spearhead)</h4></td>
    <td class="confluenceTd">
        Afghanistan, Albania, Algeria, American Samoa, Angola, Antigua and Barbuda, Aruba, Australia, Bahamas, Barbados, Benin, Belgium, Bonaire, Botswana, Bulgaria, Burma, Burundi, Cambodia, Central African Republic, Congo (Democratic
        Republic Of), Cuba, Cyprus, Curaçao, Denmark, Democratic People's Republic of Korea, Ecuador, Estonia, Ethiopia, France and its territories, Georgia, Ghana, Greece, Guam, Guinea, Guinea-Bissau, Guyana Haiti, Hong Kong, Indonesia,
        Iceland, Iran, Iraq, Israel, Isle of Man, Italy, Jamaica, Kahnawake, Kenya, Kuwait, Lao People's Democratic Republic, Lebanon, Latvia*, Liberia, Libya, Macau, Mali, Mauritius, Mexico, Mongolia, Morocco, Mozambique, Myanmar, Namibia,
        Nicaragua, North Korea, Northern Marian Islands, Pakistan, Panama, Papua New Guinea, Portugal, Puerto Rico, Qatar, Romania, Republic of Serbia, Saba, Samoa, Saudi Arabia, Sierra Leone, Singapore, Somalia, Spain, Sri Lanka, Statia,
        South Sudan, Sudan, Syria, South Africa, South Korea, St. Maarten, Switzerland, Taiwan, Trinidad and Tobago, Tunisia, the Netherlands, the Philippines, Uganda, United Arab Emirates, United Kingdom, the USA and its territories,
        Yemen, Vietnam, Virgin Islands, Venezuela and Zimbabwe
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Evoplay">Evoplay</h4></td>
    <td class="confluenceTd">
        Afghanistan, Curaçao, United Kingdom, Israel, Iraq, Iran, Jordan, <span style={{color:" rgb(23, 43, 77)"}}>Lithuania,</span> North Korea, Philippines, Pakistan, State of Palestine, Singapore, Syrian Arab Republic, Yemen
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Ezugi">Ezugi</h4></td>
    <td class="confluenceTd">
        <p>GB – United Kingdom</p>
        <p>NL - Netherlands</p>
        <p>
            US – United state<br />
            <br />
            UA - Ukraine
        </p>
        <p>RO – Romania</p>
        <p>ZA - South Africa</p>
        <p>BG – Bulgaria</p>
        <p>HU – Hungary</p>
        <p>LV – Latvia for local players</p>
        <p>CR – Costa Rica for local players</p>
        <p>ES – Spain</p>
        <p>DK – Denmark</p>
        <p>CO – Colombia</p>
        <p>IT – Italy</p>
        <p>LT – Lithuania</p>
        <p>IL -Israel</p>
        <p>AU – Australia</p>
        <p>FR – France</p>
        <p>SX +BQ - Dutch West Indies</p>
        <p>CW – Curacao</p>
        <p>VE - Venezuela</p>
        <p>Canada provinces <span style={{color:" rgb(0, 0, 0)"}}>Quebec, British Columbia, Manitoba, Ontario, Alberta (Feb 2022)</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>ZA - South Africa (from 24.11.2021)</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>SG - Singapore</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>HK - Hong Kong</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>IR - Iran</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>KP - North Korea</span></p>
        <p><span style={{color:" rgb(0, 0, 0)"}}>SD - Sudan</span></p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-FelixGaming">Felix Gaming</h4></td>
    <td class="confluenceTd"><p>the USA, Spain + the same restrictions as Groove Gaming</p></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-GrooveGaming(Fugaso)">Groove Gaming (Fugaso)</h4></td>
    <td class="confluenceTd">
        <p>Curacao: USA, GB</p>
        <p>
            Malta: Italy, the UK, France, Turkey, Australia, Belgium, Ireland, Estonia, Latvia, Slovakia, Anguilla, Czech Republic, Lithuania, Bulgaria, Colombia, Cyprus, Denmark, French Guiana, French Polynesia, French Southern
            Territories, Georgia, Gibraltar, Greece, Guadeloupe, Guernsey, Hungary, Indonesia, Iran, Isle of Man, Israel, North Korea, Martinique, Mayotte, Moldova, Myanmar, New Caledonia, Pakistan, Poland, Portugal, Reunion, Romania,
            Barthele, Saint Martin, Saint Pierre and Miquelon, Spain, Sweden, Syrian Arab republic, United states, Virgin islands, British, Virgin islands, U.S., Wallis and Futuna
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-GameArt">GameArt</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>
                <span style={{color:" rgb(33, 37, 41)"}}>
                    Australia, Belgium, Bulgaria, Columbia, Cyprus, Denmark, Estonia, France, United Kingdom, Hong Kong, Croatia, Israel, Italy, Netherlands, New Zealand, Portugal, Romania, Sweden, United States
                </span>
            </p>
            <p>
                <strong>Additional restrictions for</strong>
                <strong>
                    <em>Dragon King, Wild Dolphin, Venetia, Lady Luck, Wolf Quest, Explosive Reels, Gold Of Ra, Dancing Lion, Phoenix Princess, Fortune Panda, Magic Unicorn, Ancient Gong, Power Dragon, Jumpin Pot, African Sunset</em>
                </strong>
                :
            </p>
            <p>
                Belgium – BE<br />
                Bulgaria – BG<br />
                Cyprus – CY<br />
                Estonia – EE<br />
                France – FR<br />
                Hong Kong – HK<br />
                Israel – IL<br />
                Italy – IT<br />
                Republic of Turkey – TR<br />
                Spain – ES<br />
                USA and its territories - US<br />
                American Samoa – AS<br />
                Australia – AU<br />
                Brunei – BN<br />
                Cambodia – KH<br />
                China – CN<br />
                Hong Kong – HK<br />
                India – IN<br />
                Indonesia – ID<br />
                Japan – JP<br />
                North Korea – KP<br />
                South Korea – KR<br />
                Laos – LA<br />
                Macau – MO<br />
                Malaysia – MY<br />
                Mongolia – MN<br />
                Burma – MM<br />
                New Zealand – NZ<br />
                Papua New Guinea – PG<br />
                Philippines – PH<br />
                Singapore – SG<br />
                Sri Lanka – LK<br />
                Taiwan – TW<br />
                Thailand – TH<br />
                Timor – TL <br />
                Vietnam VN
            </p>
           
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Gamebeat">Gamebeat</h4></td>
    <td class="confluenceTd"><span style={{color:" rgb(31, 35, 38)"}}>USA, Israel, Iran</span></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Gaming1">Gaming1</h4></td>
    <td class="confluenceTd">
        <div class="table-wrap">
            <table class="wrapped confluenceTable" data-mce-resize="false">
                <tbody>
                    <tr>
                        <th class="confluenceTh"><p>Restricted Territories List</p></th>
                        <th class="confluenceTh"><p>Conditional Jurisdictions (Prior written approval of Gaming1)</p></th>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Afghanistan</p></td>
                        <td class="confluenceTd"><p>Aland</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Albania</p></td>
                        <td class="confluenceTd"><p>Alderney</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Algeria</p></td>
                        <td class="confluenceTd"><p>Argentina</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>American Samoa</p></td>
                        <td class="confluenceTd"><p>Argentina (Misiones and Tucuman)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Andorra</p></td>
                        <td class="confluenceTd"><p>Argentina (Rio Negro)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Angola</p></td>
                        <td class="confluenceTd"><p>Armenia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Antigua and Barbuda</p></td>
                        <td class="confluenceTd"><p>Belgium</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>*Argentina</p></td>
                        <td class="confluenceTd"><p>Belize</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Australia</p></td>
                        <td class="confluenceTd"><p>Bosnia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Azerbaijan</p></td>
                        <td class="confluenceTd"><p>Bulgaria</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Bahamas</p></td>
                        <td class="confluenceTd"><p>Canada</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Bahrain</p></td>
                        <td class="confluenceTd"><p>Colombia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Bangladesh</p></td>
                        <td class="confluenceTd"><p>Comoros (Anjouan only)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Barbados</p></td>
                        <td class="confluenceTd"><p>Costa Rica</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Belarus</p></td>
                        <td class="confluenceTd"><p>Croatia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Bhutan</p></td>
                        <td class="confluenceTd"><p>Curacao</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Bolivia</p></td>
                        <td class="confluenceTd"><p>Czech Republic</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Botswana</p></td>
                        <td class="confluenceTd"><p>Denmark</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Brazil</p></td>
                        <td class="confluenceTd"><p>Dominica</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Brunei Darussalam</p></td>
                        <td class="confluenceTd"><p>Dominican Republic</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Burkina Faso</p></td>
                        <td class="confluenceTd"><p>Estonia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Burundi</p></td>
                        <td class="confluenceTd"><p>French Guiana</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Cambodia</p></td>
                        <td class="confluenceTd"><p>French Southern Territories</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>China</p></td>
                        <td class="confluenceTd"><p>Georgia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Chile</p></td>
                        <td class="confluenceTd"><p>Ghana</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Christmas Island</p></td>
                        <td class="confluenceTd"><p>Gibraltar</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Cocos (Keeling) Islands</p></td>
                        <td class="confluenceTd"><p>Grenada</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Cook Islands</p></td>
                        <td class="confluenceTd"><p>Hungary</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Congo (Democratic Republic)</p></td>
                        <td class="confluenceTd"><p>India (Sikkim only)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Cuba</p></td>
                        <td class="confluenceTd"><p>Isle of Man</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Cyprus</p></td>
                        <td class="confluenceTd"><p>Italy</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Djibouti</p></td>
                        <td class="confluenceTd"><p>Jamaica</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Ecuador</p></td>
                        <td class="confluenceTd"><p>Jersey</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Egypt</p></td>
                        <td class="confluenceTd"><p>Latvia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>El Salvador</p></td>
                        <td class="confluenceTd"><p>Malta</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Eritrea</p></td>
                        <td class="confluenceTd"><p>Mexico</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Ethiopia</p></td>
                        <td class="confluenceTd"><p>Montenegro</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Fiji</p></td>
                        <td class="confluenceTd"><p>Nigeria</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>France</p></td>
                        <td class="confluenceTd"><p>Panama</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>French Polynesia</p></td>
                        <td class="confluenceTd"><p>Papua New Guinea</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Guam</p></td>
                        <td class="confluenceTd"><p>Philippines</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Guatemala</p></td>
                        <td class="confluenceTd"><p>Poland</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Guinea, Republic of</p></td>
                        <td class="confluenceTd"><p>Portugal</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Greece</p></td>
                        <td class="confluenceTd"><p>Romania</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Haiti</p></td>
                        <td class="confluenceTd"><p>Saint Kitts &amp; Nevis</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Hong Kong (Special Administrative Region of China)</p></td>
                        <td class="confluenceTd"><p>San Marino (Italy)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Iceland</p></td>
                        <td class="confluenceTd"><p>Serbia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>India (except the state of Sikkim)</p></td>
                        <td class="confluenceTd"><p>Seychelles</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Indonesia</p></td>
                        <td class="confluenceTd"><p>Slovakia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Iran</p></td>
                        <td class="confluenceTd"><p>Slovenia</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Iraq</p></td>
                        <td class="confluenceTd"><p>Spain</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Israel</p></td>
                        <td class="confluenceTd"><p>Swaziland</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Japan</p></td>
                        <td class="confluenceTd"><p>The Netherlands</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Jordan</p></td>
                        <td class="confluenceTd"><p>U.S. Virgin Islands</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Kazakhstan</p></td>
                        <td class="confluenceTd"><p>United Kingdom</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Kenya</p></td>
                        <td class="confluenceTd"><p>United States (Delaware only)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Kuwait</p></td>
                        <td class="confluenceTd"><p>United States (Nevada and New Jersey only)</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Kyrgystan</p></td>
                        <td class="confluenceTd"><p>Vanuatu</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Laos</p></td>
                        <td class="confluenceTd"><p>Switzerland</p></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Lebanon</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Lesotho</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Liberia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Libya</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Liechtenstein</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Lithuania</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Luxembourg</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Macau</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Macedonia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Malawi</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Malaysia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Mali</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Mauritius</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Moldova</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Monaco</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Mongolia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Morocco</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Mozambique</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Myanmar</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Namibia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Nauru</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Nepal</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Nicaragua</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Niger</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Niue</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Northern Mariana Islands</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>North Korea</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Oman</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Pakistan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Palestinian Territory (Gaza Strip and West Bank)</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Paraguay</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Qatar</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Russian Federation</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Rwanda</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Saudi Arabia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Sierra Leone</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Singapore</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Somalia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>South Africa</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>South Korea</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>South Sudan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Sudan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Suriname</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Syria</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Taiwan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Tajikistan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Tanzania</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Thailand</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Timor-Leste</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Trinidad &amp; Tobago</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Tunisia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Turkey</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Turkmenistan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Uganda</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Ukraine</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>United Arab Emirates</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>*United States &amp;</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Dependent/Outlying Territories</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Uruguay</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Uzbekistan</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Venezuela</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Vietnam</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Virgin Islands, British</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Yemen</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Zambia</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><p>Zimbabwe</p></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="auto-cursor-target">Netherlands</p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Gamzix">Gamzix</h4></td>
    <td class="confluenceTd">
        Aruba,<br />
        Bonaire<br />
        Curacao<br />
        Saint Martin<br />
        USA
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Habanero">Habanero</h4></td>
    <td class="confluenceTd">
        <p>
            Cyprus - CY<br />
            France - FR<br />
            Singapore - SG<br />
            United Kingdom - GB<br />
            Philippines - PH<br />
            United States - US<br />
            South Africa - ZA<br />
            Taiwan - TW<br />
            Armenia – AM<br />
            Australia -AU <br />
            Netherlands - NL<br />
            Bulgaria - BG (restricted unless licensed)<br />
            Italy - IT (restricted unless licensed)<br />
            Spain - ES (restricted unless licensed)<br />
            Portugal - PT (restricted unless licensed)
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd">
        <h4 id="RestrictedTerritories-Hub88">Hub88</h4>
        <h4 id="RestrictedTerritories-(Caleta,GreenJade,OneTouch)">(Caleta, Green Jade, One Touch)</h4>
    </td>
    <td class="confluenceTd">
        <p>OneTouch: AU, US, UK</p>
        <p>Caleta: AU, US and it's territories, GB</p>
        <p>
            GreenJade: Afghanistan, Australia, Antigua, Barbuda, Belgium, Bulgaria, Cuba, Cyprus, France, Hong Kong, Iran, Iraq, Israel, Kahnawake, Libya, Macau, Malaysia, Netherlands Antilles, Russia, Singapore, Sudan, Syria, the
            Philippines, USA and its territories
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-iGTech">iGTech</h4></td>
    <td class="confluenceTd"><p>All except Australia</p></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Infingame">Infingame</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>
                Also Ukraine for Playson
            </p>
            <p>
                <strong>GoldenRace</strong>: Afghanistan, Antigua, Belgium, <strong>Canada</strong>, Cuba, Cyprus, Denmark, <strong>Germany</strong>, Italy, France, Hong Kong, Iran, Iraq, Israel, Libya, Netherlands , Spain, Sudan, Syria,
                Philippines, Turkey (only as a market, currency can be used), USA, UK, <br />
                via Asian server:<br />
                Australia, New Zealand
            </p>
            <p><strong>bet2tech</strong>: USA</p>
            <p>
                <br />
                <strong>Booongo UPDATED</strong>
            </p>
            <p>
                - USA<br />
                - Netherlands<br />
                - Israel<br />
                - Ukraine <br />
                - Georgia
            </p>
        </div>
        <p>
            <strong>TVBet</strong>
            <strong>
                UPDATED:<br />
                <br />
            </strong>
            -Australia
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Igrosoft(Slotegrator)">Igrosoft (Slotegrator)</h4></td>
    <td class="confluenceTd">None</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-iSoftBet">iSoftBet</h4></td>
    <td class="confluenceTd">
        <p>
            Curacao set up: <br />
            Afghanistan – AF<br />
            Antigua and Barbuda – AG<br />
            Australia - AU<br />
            Belgium – BE<br />
            Cuba – CU<br />
            Denmark - DK<br />
            France – FR<br />
            French Polynesia – PF<br />
            Greece - GR <br />
            Iran – IR<br />
            Iraq – IQ<br />
            Italy - IT<br />
            Israel – IL<br />
            Libya – LY<br />
            Liechtenstein – LI<br />
            Lithuania - LT<br />
            Luxembourg – LU<br />
            Macao – MO<br />
            Malta - MT <br />
            Netherlands Antilles <br />
            Portugal - PT<br />
            Romania - RO<br />
            Spain - ES<br />
            Sudan – SD<br />
            Syrian Arab Republic – SY<br />
            United Kingdom - GB<br />
            USA – US<br />
            <br />
            Isoftbet games are available for AU and FR via Reevo integration.
        </p>
        <p>
            <span style={{color:" rgb(61, 60, 64)"}}>
                Restricted countries for MGA set up: Afghanistan, Antigua and Barbuda, Belgium, Bulgaria, China, Cuba, Cyprus, Denmark, France and its territories, Greece (<span style={{color:" rgb(23, 43, 77)"}}>license is required</span>)
                Hong Kong, Iran, Iraq, Israel, Kahnawake, Libya, Macau, Malaysia<span style={{color:" rgb(23, 43, 77)"}}>, </span>Netherlands Antilles, Republic of Serbia, Romania, Singapore, Spain, Sudan, Syria, the Philippines, Turkey, the
                USA and its territories, the United Kingdom
            </span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Kalamba">Kalamba</h4></td>
    <td class="confluenceTd">
        USA – US <br />
        Australia - AU
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-KAGaming">KA Gaming</h4></td>
    <td class="confluenceTd">Taiwan</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Lucky">Lucky</h4></td>
    <td class="confluenceTd">
        <p>
            None*<br />
            *except the regulated markets where the local license is required to operate there
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-LuckyStreak">Lucky Streak</h4></td>
    <td class="confluenceTd">
        <p>Latvia - LV</p>
        <p>Israel - IL</p>
        <p>USA – US</p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-MancalaGaming">Mancala Gaming</h4></td>
    <td class="confluenceTd">
        <span style={{color:" rgb(31, 35, 38)"}}><span> </span>France, USA</span>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><strong>Mascot</strong></td>
    <td class="confluenceTd">none</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-MrSlotty">MrSlotty</h4></td>
    <td class="confluenceTd">
        <p>1) MrSlotty: Lithuania – LT; USA – US</p>
        <p>!Для остальных продюсеров дублируются рестриктеды МрСлотти!</p>
        <p>2) EAGaming: Aruba, Bonaire, Curacao, France, The Netherlands, Saba, Statia, St. Maarten, Singapore.</p>
        <p>3) Fazi: Germany, Netherlands, Australia</p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-NetGame">NetGame</h4></td>
    <td class="confluenceTd">France, Netherlands, Curacao, United Kingdom, USA</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-NolimitCity">Nolimit City</h4></td>
    <td class="confluenceTd">
        <p>
            Australia - AU (only for new operators)<br />
            Iran - IR<br />
            North Korea - KP<br />
            Ontario <br />
            Cuba - CU<br />
            Sudan - SD<br />
            Syria - SY<br />
            Netherlands - NL
        </p>
        <p><br /></p>
        <p>
            The United States of America, its territories and possessions (except under a local licence) - US<br />
            <em>Any country in which gambling is expressly illegal</em><br />
            <em>Any country that requires a gambling licence (except under a local licence)</em>
        </p>
        <p><br /></p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Novomatic">Novomatic</h4></td>
    <td class="confluenceTd">
        Albania, Armenia, Australia, Austria, Belgium, Bulgaria, Canada, Colombia, Croatia (Hrvatska), Cyprus, Czech Republic, Denmark, Egypt, Estonia, France, French Guiana, Georgia, Germany, Greece, Guadeloupe, Hong Kong S.A.R., Hungary,
        Iran, Ireland, Italy, Kosovo, Latvia, Liechtenstein, Lithuania, Luxembourg, Macau S.A.R., Macedonia, Malta, Martinique, Moldova, Montenegro, Netherlands, New Zealand, Poland, Portugal, Romania, Russia, Serbia, Singapore, Slovakia,
        Slovenia, Spain, Sweden, Switzerland, Turkey, United Arab Emirates, United Kingdom &amp; Northern Ireland, United States.
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Nucleus">Nucleus</h4></td>
</tr>
<tr>
    <td class="confluenceTd">None</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-NYX">NYX</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>Dice games restrictions:</p>
          
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><strong>Onlyplay</strong></td>
    <td class="confluenceTd"><span style={{color:" rgb(61, 60, 64)"}}>American Samoa, Curaçao, Cyprus, Guam, Lithuania, Northern Mariana Islands, Puerto Rico, United States, Virgin Islands, U.S</span></td>
</tr>
<tr>
    <td class="confluenceTd">
        <h4 id="RestrictedTerritories-ORYX(Gamomat,candlebets,givme,goldenhero,Peter&amp;Sons)">ORYX (Gamomat, <span style={{color:" rgb(23, 43, 77)"}}>candlebets, givme, golden hero, Peter&amp;Sons)</span></h4>
    </td>
    <td class="confluenceTd">
        <blockquote>
            <p>
                <span style={{color:" rgb(0, 0, 0)"}}>
                    Afghanistan, Algeria, Australia (its territories and possessions), Bahrain, <strong>Canada</strong>, Cuba, Eritrea, France (its territories and possessions) (except with local licence), Germany, Hong Kong, Jordan, Iran,
                    Iraq, Israel (its territories and possessions), Italy (except with local licence), Kuwait, Libya, Lithuania (except with local licence), Mauritius, Netherlands, North Korea, Saudi Arabia, Singapore, Slovenia (except with
                    local licence), Somalia, South Sudan, Sudan, Switzerland (except with local licence), Syria, Turkey, UAE, United States of America (its territories and possessions) (except with local licence), Yemen,
                    <strong>Belgium</strong>
                </span>
            </p>
            <p>
                <span style={{color:" rgb(0, 0, 0)"}}>
                    <strong><span style={{color:" rgb(34, 34, 34)"}}>a local license is needed in Switzerland, Greece, Denmark, Sweden, Poland, Italy, France, Lithuania, Latvia, Estonia and Germany.</span></strong>
                </span>
            </p>
        </blockquote>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><strong>Pariplay</strong></td>
    <td class="confluenceTd">
        <p><strong>Prohibited</strong>:</p>
        <ul >
            <li>Afghanistan</li>
        </ul>
        <p>
            • Albania<br />
            • Algeria<br />
            • Angola<br />
            • Australia<br />
            • The Bahamas<br />
            • Barbados<br />
            • Botswana<br />
            • Cambodia<br />
            • Congo (Republic of)<br />
            • Cyprus<br />
            • Democratic People’s Republic of Congo<br />
            • Ecuador<br />
            • France and its territories*<br />
            • Ghana<br />
            • Guyana<br />
            • Hong Kong<br />
            • Iran<br />
            • Iraq<br />
            • Israel<br />
            • Jamaica<br />
            • Latvia<br />
            • Lithuania<br />
            • Mauritius<br />
            • Mongolia<br />
            • Myanmar<br />
            • Nicaragua<br />
            • North Korea<br />
            • Pakistan<br />
            • Panama<br />
            • Philippines<br />
            • Poland<br />
            • Saudi Arabia<br />
            • Singapore<br />
            • South Africa<br />
            • South Korea<br />
            • South Sudan<br />
            • Sudan<br />
            • Syria<br />
            • Taiwan<br />
            • Uganda<br />
            • United States of America<br />
            • Vatican City<br />
            • Yemen<br />
            • Zimbabwe<br />
            *<strong>French territories are:</strong><br />
            • Guadeloupe<br />
            • Martinique<br />
            • French Guiana<br />
            • Réunion<br />
            • Mayotte<br />
            • Saint Martin<br />
            • Saint Barthélemy<br />
            • Saint Pierre and Miquelon<br />
            • New Caledonia<br />
            • Wallis and Futuna<br />
            • French Polynesia<br />
            • Clipperton<br />
            • French Southern and Antarctic Lands
        </p>
        <p>
            <br />
            <strong>Restricted Jurisdictions</strong> (a copy of Operator’s license must be supplied to Pariplay prior to any activity):<br />
            • Belgium<br />
            • Bulgaria<br />
            • Czech Republic<br />
            • Colombia<br />
            • Denmark<br />
            • Estonia<br />
            • Greece<br />
            • Italy<br />
            • Malta<br />
            • Portugal<br />
            • Romania<br />
            • Spain<br />
            • Sweden<br />
            • United Kingdom
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd">Pipa Games</td>
    <td class="confluenceTd"><p>China; Cuba; Cyprus; Israel; Iran, Islamic Republic of; Latvia; Myanmar; Sudan; Syrian Arab Republic; Turkey; United States</p></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Playtech">Playtech</h4></td>
    <td class="confluenceTd">
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}>
                Australia, Azerbaijan, Belarus, Cyprus, Hong Kong, Israel, Latvia, Lithuania, Macau, Ontario, Poland, the Philippines, Singapore, Ukraine, the USA and its territories,
                <span style={{color:" rgb(34, 34, 34)"}}>Tunisia, <span style={{color:" rgb(0, 0, 0)"}}>Turkey</span><span> </span></span>
            </span>
        </p>
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}>
                Belgium, Bulgaria, Colombia, the Czech Republic, Denmark, Estonia, France, Italy, Portugal, Romania, Slovakia, Spain, Sweden, Switzerland, South Africa and the United Kingdom, Greece, Germany, Netherlands
            </span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-PlayReels">PlayReels</h4></td>
    <td class="confluenceTd">None</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Platipus">Platipus</h4></td>
    <td class="confluenceTd"><p>None</p></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Playngo">Playngo</h4></td>

<td class="confluenceTd">
    <div class="content-wrapper">
        <p><strong>Curacao</strong>:</p>
        <p>
            AFGHANISTAN; ALDERNEY; AMERICA SAMOA; ANGOLA; AUSTRALIA; BELGIUM; BOSNIA AND HERZEGOVINA; CAMBODIA; CROATIA; CZECH REPUBLIC; DEMOCRATIC PEOPLE ́S REPUBLIC OF KOREA(DPRK); DENMARK; GEORGIA, DUTCH CARRIBEAN INCLUDING CURACAO AND
            ARUBA; ETHIOPIA; GERMANY; HUNGARY;IRAQ; ISLAMIC REPUBLIC OF IRAN; ITALY; LAO ́S PEOPLE'S DEMOCRATIC REPUBLIC; LATVIA; LITHUANIA, PORTUGAL, ROMANIA; SERBIA; SINGAPORE; SPAIN; SWEDEN; SWITZERLAND; SYRIANARAB REPUBLIC; UGANDA;
            UNITED KINGDOM; USA; VANUATU; YEMEN;MALTA; Ontario (province in Canada) from 04.04.2022
        </p>
        <p>Skate for Gold - Canada</p>
        <p>
            B2B (incl Hollycorn casinos) customers: all above<strong> + Lichtenstein + following EU countries</strong>: Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, France, Germany, Greece, Hungary, Ireland, Italy,
            Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden)
        </p>
        <p><br /></p>
        <p><br /></p>
        <p><strong>Malta</strong>:</p>
        <p>
            AFGHANISTAN; ALDERNEY; AMERICA SAMOA; ANGOLA; AUSTRALIA; BELGIUM; BOSNIA AND HERZEGOVINA; CAMBODIA; CROATIA; CZECH REPUBLIC; DEMOCRATIC PEOPLE ́S REPUBLIC OF KOREA(DPRK); DENMARK; GEORGIA, DUTCH CARRIBEAN INCLUDING CURACAO AND
            ARUBA; ETHIOPIA; HUNGARY;IRAQ; ISLAMIC REPUBLIC OF IRAN; ITALY; LAO ́S PEOPLE'S DEMOCRATIC REPUBLIC; LATVIA; LITHUANIA, PORTUGAL, ROMANIA; SERBIA; SINGAPORE; SPAIN; SWEDEN; SWITZERLAND; SYRIANARAB REPUBLIC; UGANDA; UNITED
            KINGDOM; USA; VANUATU; YEMEN
        </p>
        <p>Skate for Gold - Canada</p>
        <p>Germany - working games marked &quot;Yes&quot; in column &quot;Germany&quot;</p>
        <p><br /></p>
        <p><br /></p>
        <p><strong>Country can be opened with national license acquisition for WL brands</strong></p>
    </div>
</td>

</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-PragmaticPlay">Pragmatic Play</h4></td>
    <td class="confluenceTd">
        <p>
            restricted by GeoIP : US, FR, IL, UA, TW, AU, KP, CY, PH, SG<br />
            1. USA<br />
            2. France<br />
            3. Israel<br />
            4. Taiwan<br />
            5. Australia<br />
            6. North Korea<br />
            <span>7. Philippines</span>
            <span>
                <br />
                8.
            </span>
            <span>
                Singapore<br />
                9. India (regions: Nagaland, Sikkim, Telangana, Andhra Pradesh)<br />
            </span>
        </p>
        <p><br /></p>
        <p>regulated markets : UK, IT, DK, BS, ES, RO, BG, PT, GI, PH, LT, LV, EE, SE, CO, RS</p>
        <ol>
            <li><span> Great Britain</span></li>
            <li><span> Italy</span></li>
            <li><span> Denmark</span></li>
            <li><span> Bahamas</span></li>
            <li><span> Spain</span></li>
            <li><span> Romania</span></li>
            <li><span> Bulgaria</span></li>
            <li><span> Portugal</span></li>
            <li><span> Gibraltar</span></li>
            <li><span> Lithuania</span></li>
            <li><span> Sweden (open for SoftSwiss)</span></li>
            <li>
                <span> South Africa<br /></span>
            </li>
            <li>
                <span> Serbia<br /></span>
            </li>
            <li><span> Ukraine</span></li>
            <li><span>Ontario (Canada) from 04.04.2022</span></li>
        </ol>
        <p><br /></p>
        <p>
            <span>fee group <strong>branded </strong>(game Peaky Blinders): Russia (additionally to the general restrictions)</span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-PushGaming">Push Gaming</h4></td>
    <td class="confluenceTd">
        Afghanistan, American Samoa, Australia, Belgium, Cuba, Denmark, Egypt, Eritrea, Spain, Spain, France, Greenland, Guam, Hong Kong, Israel, Iraq, Iran, Islamic Republic of, Italy, Korea, Democratic People's Republic of, Kuwait,
        Marshall Islands, Myanmar, Macao, Malaysia, Nigeria, Netherlands, French Polynesia, Poland, Puerto Rico, Qatar, Sudan, Sweden, Singapore, Taiwan,
        <span style={{color:" rgb(31, 35, 38)"}}><span style={{color:" rgb(34, 34, 34)"}}>United Kingdom</span></span>, United States, Holy See (Vatican City State), Virgin Islands, U.S., Yemen, South Africa, Zimbabwe, Netherlands,Malta
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-RelaxGaming(Relax,SilverBullet,BTG,PGSoft,HacksawP2P,High5)">Relax Gaming (Relax, Silver Bullet, BTG, PG Soft, Hacksaw P2P, High5)</h4></td>
    <td class="confluenceTd">
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}>
                <strong>Common Restricted territories</strong> : Afghanistan, Algeria, Angola, Antigua &amp; Barbuda, Australia, Cambodia, China, Cuba, Cyprus, Guyana, Hong Kong, Indonesia, Iran, Iraq, Israel, Kahnawake, Kuwait, Libya,
                Macau, Myanmar, Namibia,<span style={{color:" rgb(51, 51, 51)"}}> </span>Netherlands Antilles, North Korea, Pakistan, Papua New Guinea, Sudan, Syria, The Philippines, Uganda, United States of America and its Territories, Yemen
            </span>
        </p>
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}><strong>Common Regulated territories</strong> : <span style={{color:" rgb(33, 37, 41)"}}>Armenia, </span></span>
            <span style={{color:" rgb(31, 35, 38)"}}>
                Belgium, <span style={{color:" rgb(33, 37, 41)"}}>Colombia,</span> Denmark, Estonia, France and its Territories, <span style={{color:" rgb(33, 37, 41)"}}>Georgia,</span> Italy, Latvia,
                <span style={{color:" rgb(33, 37, 41)"}}>
                    Lithuania, Mexico, <span style={{color:" rgb(31, 35, 38)"}}><span style={{color:" rgb(51, 51, 51)"}}>Netherlands (including the special municipalities of Bonnaire, St Eustatius, and Saba), Ontario, </span></span>
                </span>
                Republic of Serbia, Romania, Spain, Sweden, United Kingdom
            </span>
        </p>
        <p>
            <strong><span style={{color:" rgb(31, 35, 38)"}}>Additional restrictions per providers: </span></strong>
            <span style={{color:" rgb(31, 35, 38)"}}>
                <br />
                <strong>PG Soft:</strong> Latvia, Malaysia, Singapore, Taiwan
            </span>
        </p>
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}><strong>BTG:</strong> Australia, Canada</span>
        </p>
        <p>
            <span style={{color:" rgb(31, 35, 38)"}}>
                <strong>4theplayer:</strong> Australia<br />
                <strong>Revolver: </strong>
            </span>
            Albania, Belgium, Bulgaria, Czech Republic, Colombia, Denmark, Ecuador, France and its territories, Lithuania, Poland, Portugal, Puerto Ricco, Romania, Saudi Arabia, Singapore, South Korea, Spain, Vatican City<br />
            <br />
            <strong>Hacksaw Gaming Premium (hacksaw_premium): </strong> Zimbabwe, Malta, Australia, France<br />
            <strong>High5:</strong> Netherlands, Belarus, Russia
        </p>
        <p>
            <span style={{color:" rgb(23, 43, 77)"}}><strong>Northern Light:</strong> Germany, Taiwan</span>
        </p>
        <p>
            <span style={{color:" rgb(23, 43, 77)"}}><strong>Sapphire Gaming</strong>: Germany, Taiwan</span>
        </p>
        <p>
            <span style={{color:" rgb(23, 43, 77)"}}><strong>Sthlmgaming</strong>: Germany, Taiwan</span>
        </p>
        <p>
            <span style={{color:" rgb(23, 43, 77)"}}>
                <strong>
                    Reel Play / Reel Play studio content (R<span style={{color:" rgb(33, 37, 41)"}}>eel play,<span> B</span></span><span style={{color:" rgb(33, 37, 41)"}}>addingo, BBgames, Boomerang Studios, Games Lab</span>)
                </strong>
                : Canada
            </span>
        </p>
        <p>
            <strong>Silverback Gaming:</strong>
            <span style={{color:" rgb(23, 43, 77)"}}>
                'AL', 'AO', 'AZ', 'BD', 'BH', 'BB', 'BY', 'BJ', 'BN', 'BG', 'BF', 'BI', 'KH', 'CM', 'CA', 'CV', 'CF', 'KM', 'CK', 'CI', 'CY', 'CZ', 'DJ', 'EG', 'ER', 'ET', 'FO', 'FI', 'GM', 'GH', 'GR', 'GL', 'GD', 'IN', 'ID', 'KZ', 'KE',
                'KG', 'LA', 'LB', 'LR', 'LI', 'MO', 'MY', 'ML', 'MH', 'MR', 'MD', 'MC', 'MA', 'MN', 'NA', 'NR', 'NI', 'NE', 'NG', 'NO', 'OM', 'PW', 'PS', 'PL', 'PT', 'PR', 'QA', 'RU', 'RW', 'WS', 'ST', 'SA', 'SL', 'SB', 'SO', 'ZA', 'KR',
                'LK', 'SR', 'CH', 'TJ', 'TH', 'TG', 'TO', 'TT', 'TN', 'TR', 'TM', 'UG', 'UA', 'AE', 'TW', 'TZ', 'UM', 'US', 'VI', 'ZM', 'VN', 'VU', 'UZ'
            </span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Quickfire">Quickfire</h4></td>
    <td class="confluenceTd">
        <strong>A. Restricted Territories:</strong>
        <p>
            USA, Singapore, Italy, Denmark, South Africa, France, UK, Spain, Belgium, Australia, Taiwan, Philippines, Sweden, Switzerland, Romania, the Netherlands, Ontario (Canada) from 04.04.2022, Russia (from 11.04.2022), Belarus (from
            11.04.2022)<br />
            <br />
            <strong>Regulated Territories:</strong>
        </p>
        <p>Greece</p>
        <p><br /></p>
        <p><em>Big time gaming : all countries above plus Canada</em></p>
        <p>
            <em>
                <br />
                <span style={{color:" rgb(23, 43, 77)"}}><strong>Eyecon (in addition to general Restricted Territories in A.)</strong>: </span>
            </em>
        </p>
        <p>
            <em>
                <span style={{color:" rgb(23, 43, 77)"}}>
                    Cyprus (Excluding Sports By License)<br />
                    Hong Kong<br />
                    Israel<br />
                    Latvia (live only)<br />
                    Macau<br />
                    Philippines<br />
                    Ukraine (Poker Allowed)<br />
                    USA and its territories (Excluding New Jersey by License)<br />
                    Greece<br />
                    Lithiania<br />
                    Germany<br />
                    Angola <br />
                    Azerbaijan<br />
                    Burundi<br />
                    Cameroon<br />
                    Democratic People's Republic of Korea<br />
                    Democratic Republic of Congo <br />
                    Equatorial Guinea <br />
                    Eritrea<br />
                    Iran<br />
                    Iraq<br />
                    Libya<br />
                    Mali<br />
                    Mozambique<br />
                    Maynmar<br />
                    Republic of Guinea<br />
                    Republic of Guinea-Bissau<br />
                    Sierre Leone<br />
                    Somalia<br />
                    South Sudan<br />
                    Sudan
                </span>
            </em>
        </p>
        <p>
            <em>
                <span style={{color:" rgb(23, 43, 77)"}}>
                    Sweden<br />
                    Syria<br />
                    Yemen
                </span>
            </em>
        </p>
        <p>
            <em><span style={{color:" rgb(23, 43, 77)"}}>Germany</span></em>
        </p>
        <p>
            <em><span style={{color:" rgb(23, 43, 77)"}}>Belarus</span></em>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-QuickSpin">QuickSpin</h4></td>
    <td class="confluenceTd">
        <p>
            Australia, Belarus, Belgium, Bulgaria, Colombia, Cyprus, Denmark, Estonia, France, Greece, Germany (for operators that do not hold a local license), Hong Kong, Israel, Italy, Macau, Lithuania, Ontario, Poland, Portugal, Romania,
            Singapore, Spain, Switzerland, the Czech Republic, the Philippines, the United Kingdom, the USA, Sweden, Ukraine, The <span style={{color:" rgb(31, 35, 38)"}}>Netherlands</span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Skywind">Skywind</h4></td>
    <td class="confluenceTd">
        <p>
            Australia, Belgium, Bulgaria, Colombia, Czech Republic, Cyprus, Estonia, France and its territories (including French Guiana, French Polynesia, French Southern Territories, the Isle of Man, Martinique, Guadeloupe, Reunion,
            Mayotte), Hong Kong, Israel, Italy, Latvia, Macau, Poland, Portugal, the Republic of Belarus, Romania, Singapore, Spain, Turkey, the Philippines, Ukraine, the United Kingdom, the USA and its territories (including United States
            Minor Outlying Islands), Denmark, Greece, Sweden, Denmark, Greece.
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><strong>Slotmill</strong></td>
    <td class="confluenceTd">
        Curacao license: UK, USA<br />
        Malta license: UK, USA, Curacao
    </td>
</tr>

<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-SmartSoftGaming">SmartSoft Gaming</h4></td>
    <td class="confluenceTd"><span style={{color:" rgb(33, 37, 41)"}}>Georgia, France, Belarus, Armenia</span></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Spribe">Spribe</h4></td>
    <td class="confluenceTd">Armenia, Georgia, <span style={{color:" rgb(23, 43, 77)"}}>Russia</span></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-RedTiger">Red Tiger</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p>
                <span style={{color:" rgb(31, 35, 38)"}}>
                    Restricted: <span style={{color:" rgb(34, 34, 34)"}}>Afghanistan, Albania, Algeria, Angola, <strong>Australia</strong>, Bahamas, Botswana, Bulgaria, Ecuador, Ethiopia, Georgia,<span> </span></span>
                    <span style={{color:" rgb(34, 34, 34)"}}>Ghana, Guyana, Hong Kong, Iran, Iraq, Israel, Kuwait, Namibia, Nicaragua, North Korea, Pakistan, Panama, Philippines, </span>
                    <span style={{color:" rgb(34, 34, 34)"}}>Singapore, Sudan, Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, Yemen, Zimbabwe</span>
                </span>
            </p>
            <p>
                <span style={{color:" rgb(31, 35, 38)"}}>
                    Regulated: <span style={{color:" rgb(34, 34, 34)"}}>Belgium, Colombia, Croatia, Czech Republic, Denmark, Estonia, </span><span style={{color:" rgb(34, 34, 34)"}}>France, Germany, Italy, Latvia, </span>
                    <span style={{color:" rgb(34, 34, 34)"}}>Lithuania, Netherlands, Mexico, Portugal, Romania, Serbia, </span><span style={{color:" rgb(34, 34, 34)"}}>Spain, Sweden, Switzerland, </span>
                    <span style={{color:" rgb(34, 34, 34)"}}>
                        United Kingdom, United States of America; Canada (<span style={{color:" rgb(0, 0, 0)"}}>Quebec, British Columbia, Manitoba, Ontario, Alberta (feb 2022)</span>),
                        <span style={{color:" rgb(0, 0, 0)"}}>South Africa (from 24.11.2021), <strong>Ukraine, </strong>Venezuela (Dec 2021)</span>
                    </span>
                </span>
            </p>
         
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Spinomenal/RetroGaming">Spinomenal / Retro Gaming</h4></td>
    <td class="confluenceTd">
        <p>Curacao - Israel, USA, <span>UK, </span><span>Hungary, </span><span>Australia</span></p>
        <p><span>MGA - USA, Australia, France, Italy, Portugal, Spain, Turkey, Israel</span></p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Swintt">Swintt</h4></td>
    <td class="confluenceTd">
        <p>
            <em>
                <strong><u>Curacao:</u></strong>
            </em>
            Hong Kong, Philippines, Singapore, Taiwan, United States, Netherlands
        </p>
        <p>
            <strong>
                <u><em>Malta:</em></u>
            </strong>
            Afghanistan, Antigua and Barbuda, Albania, Angola, Australia, Belgium, China, Cuba, Cyprus, Denmark, Algeria, Estonia, Spain, France, United Kingdom, Guyana, Indonesia, Israel, Iraq, Iran, Islamic Republic of, Italy, Cambodia,
            Kuwait, Lithuania, Latvia, Libya, Myanmar, Macao, Namibia, Papua New Guinea, Pakistan, Romania, Serbia, Sudan, Syrian Arab Republic, Turkey, Uganda, Yemen, Zimbabwe, Netherlands
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Thunderkick">Thunderkick</h4></td>
    <td class="confluenceTd">
        <p>
            <span style={{color:" rgb(34, 34, 34)"}}>Australia, Belgium, Czech Republic, Denmark, France, Iraq, Iran, Italy, Libya, Myanmar, Nigeria, </span>
            <span style={{color:" rgb(34, 34, 34)"}}>People's Republic of Korea, Sudan, Portugal, Romania, Spain, USA, Zimbabwe</span>
        </p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><strong>Tom Horn</strong></td>
    <td class="confluenceTd"><p>USA, Israel;</p></td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-TrueLab">TrueLab</h4></td>
    <td class="confluenceTd">
        <p>
            <strong><u>Curacao:</u></strong>
        </p>
        <p>
            Australia, the United States and its dependencies and territories including North Mariana Islands, Puerto Rico, and the US Virgin Islands, Guam, American Samoa, as well as Curacao, France, French Guiana, French Polynesia, French
            Southern Territories, Gibraltar, Malta, Netherlands, Russia, Reunion, Saba, St Eustatius, St Martin, Saint Pierre and Miquelon, Saint-Barthelemy and Wallis and Ukraine
        </p>
        <p>
            <strong><u>Malta:</u></strong>
        </p>
        <p>Australia, the United States and its dependencies and territories including North Mariana Islands, Puerto Rico, and the US Virgin Islands, Guam, American Samoa as well as Curacao, Gibraltar, Russia and Ukraine</p>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Yggdrasil">Yggdrasil</h4></td>
    <td class="confluenceTd">
        <div class="content-wrapper">
            <p><strong>For MGA: </strong></p>
            <p>
                <span>
                    Afghanistan<br />
                    Albania<br />
                    Alderney (unless under a local licence)<br />
                    Algeria<br />
                    American Samoa<br />
                    Angola<br />
                    Antigua and Barbuda (unless under a local licence and explicit approval)<br />
                    Argentina (unless under a local licence and explicit approval)
                </span>
            </p>
            <p><span>Australia (unless under a local licence and explicit approval) </span></p>
            <p>
                <span>Austria (unless under a local licence and explicit approval) - <strong>there are no technical restrictions</strong></span>
            </p>
            <p>
                <span>
                    Bahamas<br />
                    Barbados<br />
                    Belarus<br />
                    Belgium (unless under a local licence)<br />
                    Benin<br />
                    Bosnia &amp; Herzegovina (unless under a local licence and explicit approval)<br />
                    Botswana<br />
                    Brazil (unless under a local licence and explicit approval) Bulgaria (unless under a local licence and explicit approval) Burkina Faso<br />
                    Burundi<br />
                    Cambodia<br />
                    Canada (Ontario and Kahnawake) (unless under a local licence and explicit approval) - <strong>there are no technical restrictions</strong><br />
                    Cape Verde<br />
                    Cayman Islands<br />
                    Central African Republic<br />
                    China
                </span>
            </p>
            <p><span>Colombia (unless under a local licence and explicit approval) </span></p>
            <p>
                <span>
                    Congo<br />
                    Cote d'Ivoire<br />
                    Croatia (unless under a local licence and explicit approval)
                </span>
            </p>
            <p><span>Cuba</span></p>
            <p>
                <span>
                    Curacao<br />
                    Cyprus (unless under a local licence and explicit approval)
                </span>
            </p>
            <p>
                <span>
                    Czech Republic (unless under a local licence)<br />
                    Denmark (unless under a local licence)<br />
                    Egypt<br />
                    Eritrea<br />
                    Estonia (unless under a local licence)<br />
                    Ethiopia<br />
                    France and overseas territories ((unless under a local licence and explicit approval)
                </span>
            </p>
            <p><span>Georgia (unless under a local license) </span></p>
            <p>
                <span>Germany (unless under a local license)- <strong>there are no technical restrictions</strong></span>
            </p>
            <p>
                <span>
                    Ghana<br />
                    Gibraltar (unless under a local licence)
                </span>
            </p>
            <p><span>Greece (unless under a local licence) </span></p>
            <p><span>Greenland (.DK) (unless under a local licence) </span></p>
            <p><span>Guam</span></p>
            <p>
                <span>
                    Guinea Bissau<br />
                    Haiti<br />
                    Holy See (Vatican City)<br />
                    Hungary<br />
                    Iran<br />
                    Iraq<br />
                    Isle of Man (unless under a local licence) Israel<br />
                    Italy (unless under a local licence)
                </span>
            </p>
            <p>
                <span>
                    Jamaica<br />
                    Jersey (unless under a local licence and explicit approval) Jordan<br />
                    Kenya (unless under a local licence and explicit approval) Kuwait<br />
                    Laos<br />
                    Lebanon<br />
                    Libya<br />
                    Lithuania (unless under a local licence)- <strong>there are no technical restrictions</strong><br />
                    Malaysia<br />
                    Mali<br />
                    Marshall Islands<br />
                    Mauritius<br />
                    Mexico (unless under a local licence and explicit approval) Morocco<br />
                    Mozambique<br />
                    Myanmar<br />
                    Nicaragua<br />
                    Nicaragua (unless under a local licence and explicit approval) Nigeria<br />
                    North Korea<br />
                    Occupied Palestinian Territory<br />
                    Pakistan<br />
                    Panama<br />
                    Panama (unless under a local licence and explicit approval) Philippines<br />
                    Poland<br />
                    Portugal (unless under a local licence and explicit approval) Puerto Rico<br />
                    Russia
                </span>
            </p>
            <p>
                <span>
                    Qatar<br />
                    Romania (unless under a local licence)<br />
                    Senegal<br />
                    Sierra Leone<br />
                    Singapore<br />
                    Slovakia (unless under a local licence)<br />
                    Somalia<br />
                    South Africa<br />
                    South Sudan<br />
                    Spain (unless under a local licence)<br />
                    Sri Lanka<br />
                    Sudan<br />
                    Sweden (unless under a local licence) - <strong>there are no technical restrictions</strong><br />
                    Switzerland (unless under a local licence)- <strong>there are no technical restrictions</strong><br />
                    Syria<br />
                    Taiwan<br />
                    The Netherlands (unless under a local licence) - <strong>there are no technical restrictions</strong><br />
                    Trinidad &amp; Tobago<br />
                    Tunisia<br />
                    Uganda<br />
                    Ukraine (unless under a local licence and explicit approval) United Kingdom (unless with a local licence)<br />
                    USA and all territories (unless under a local licence and explicit approval)<br />
                    Vanuatu (unless under a local licence and explicit approval) Venezuela<br />
                    Vietnam<br />
                    Western Sahara<br />
                    Yemen<br />
                    Zimbabwe
                </span>
            </p>
            <p><br /></p>
            <p><strong>For Curacao - Malta</strong></p>
            <p><br /></p>
            <p>&quot;Nikola Tesla's Incredible Machine&quot; (Release: September 05, 2019) - won't be available for Austria</p>
        </div>
    </td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-VivoGaming">Vivo Gaming</h4></td>
    <td class="confluenceTd"><span style={{color:" rgb(34, 34, 34)"}}>Israel, Costa Rica, Argentina, United States</span></td>
</tr>

<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Wazdan">Wazdan</h4></td>
    <td class="confluenceTd">US, UK, Armenia, Switzerland, Poland, Greece</td>
</tr>
<tr>
    <td class="confluenceTd"><h4 id="RestrictedTerritories-Zillion">Zillion</h4></td>
    <td class="confluenceTd">Israel, Iran</td>
</tr>

 </tbody>
    </table>
</div>
</div>
</div>
</div>
</section>  
            </div>
    )
};

export default RestrictedTerritories;