import React from 'react';

const GameProviders = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Game providers and games</h3>
<p>We are providing json file with all parameter to be used in creating session or playing games</p>

<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Live Games</span></p>
<p>Download file - <a href="/document/CasinoListCasinoland-live.xlsx">Live Games</a></p>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Slot Games</span></p>
<p>Download file - <a href="/document/CasinoListCasinoland-slot.xlsx">Slot Games</a></p>


</div>
</div>
</div>
</section>  
            </div>
    )
};

export default GameProviders;