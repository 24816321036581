import React from 'react';

const introduction = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Games API</h3>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Integration</span></p>
<p>Client provides  CLIENT_API_URL and receives our gaming server url (BACKEND_URL), ServerID and AUTH_TOKEN.</p>
<p>We use following terms:</p>
<p>CLIENT_API_URL - client's api url, example https://api.casinoland.com/. All requests are sent to this address.</p>
<p>BACKEND_URL - our backend server base url, example https://play.casinoland.com/</p>
<p>SERVER_ID - is used for identification at backend server.</p>
<p>AUTH_TOKEN - token that is used to sign requests from backend (us) to client.</p>
<p>We use JSON format for serialising data.</p>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>I18n</span></p>
<p>Current available locales: en.</p>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Currency</span></p>
<p>Current available locales: en.</p>
<p>Available non-ISO currencies: BTC (1 unit = 1 000 000 00 subunits), FUN (1 unit = 100 subunits).</p>
<p>All amounts are transferred in subunits.</p>
<p>Example:</p>
<p>12.5 USD is represented as 1250.</p>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Error Codes</span></p>
<div class="table-wrap">
    <table class="table table-bordered wrapped confluenceTable">
        <tbody>
            <tr>
                <th class="confluenceTh">HTTP status</th>
                <th class="confluenceTh">Description</th>
            </tr>
            <tr>
                <td class="confluenceTd">200</td>
                <td class="confluenceTd">OK</td>
            </tr>
            <tr>
                <td class="confluenceTd">201</td>
                <td class="confluenceTd">created</td>
            </tr>
            <tr>
                <td class="confluenceTd">400</td>
                <td class="confluenceTd"><p>invalid arguments</p></td>
            </tr>
            <tr>
                <td colspan="1" class="confluenceTd">403</td>
                <td colspan="1" class="confluenceTd">signature mismatch, invalid token</td>
            </tr>
            <tr>
                <td colspan="1" class="confluenceTd">404</td>
                <td colspan="1" class="confluenceTd">not found</td>
            </tr>
            <tr>
                <td colspan="1" class="confluenceTd">412</td>
                <td colspan="1" class="confluenceTd">insufficient funds</td>
            </tr>
            <tr>
                <td colspan="1" class="confluenceTd">500</td>
                <td colspan="1" class="confluenceTd">internal error</td>
            </tr>
        </tbody>
    </table>
</div>
<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Playing process</span></p>
<img src="/images/play-process.png" style={{width:"80%"}} />
   
</div>


</div>
</div>
</section>  
            </div>
    )
};

export default introduction;