import React from 'react';

const jsonrequest = {"user":"jaipur4",
"token":"ACCESS_TOKEN",
"partner_id":"PARTNER_ID",
"platform":"GPL_DESKTOP",  /// GPL_MOBILE
"req_url":"https://domain.com",
"lang":"en",
"ip":"CURRENT_IP_ADDRESS",
"game_id":"evolution:top_games",
"game_code":"evolution:top_games",
"id":"REFERENCE_USER_ID",
"balance":12010
}
const jsonresponse = {url:"GAME_PLAY_LINK"};
const Tvapi = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Get UPCOMING MATCH TV URL LIST</h3>
<p>We are providing json file with all parameter to be used in get streaming Tv URL</p>

<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Get Streaming URL</span></p>

<strong>CLIENT_API_URL : https://api.casinoland.live/api/v1/front-end/getstreamurllist</strong>
<p><strong>Method:</strong> POST</p> 
<p><strong>Request</strong></p> 
<pre>
  <code> {JSON.stringify( {
   "token": "TOKEN FROM PROVIDER"
 })}
</code>
</pre>
<p><strong>Sample Response</strong></p> 
<pre>
  <code> {JSON.stringify({
    "message": "ok",
    "result": [
        {
            "match_id": 31828432,
            "match_name": "Flipkens/Siegemund v Gabueva/Zakharova",
            "match_type": "TENNIS",
            "match_start": "2022-10-15T15:30:00.000Z",
            "match_live": 0,
            "match_league": "WTA Cluj - Napoca 2022",
            "match_url": "STREAMING_URL"
        }]})}
</code>
</pre>

</div>

<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Get UPCOMING MATCH TV URL BY MATCH ID</h3>
<p>We are providing json file with all parameter to be used in get streaming Tv URL</p>

<p><span style={{fontSize:"18.0pt",fontWeight: "bold",lineHeight: "normal"}}>Get Streaming URL</span></p>

<strong>CLIENT_API_URL : https://api.casinoland.live/api/v1/front-end/getstreamurl</strong>
<p><strong>Method:</strong> POST</p> 
<p><strong>Request</strong></p> 
<pre>
  <code> {JSON.stringify( {
   "token": "TOKEN FROM PROVIDER",
   "match_id":31832551
 })}
</code>
</pre>
<p><strong>Sample Response</strong></p> 
<pre>
  <code> {JSON.stringify({
    "message": "ok",
    "result": 
        {
            "match_id": 31828432,
            "match_name": "Flipkens/Siegemund v Gabueva/Zakharova",
            "match_type": "TENNIS",
            "match_start": "2022-10-15T15:30:00.000Z",
            "match_live": 0,
            "match_league": "WTA Cluj - Napoca 2022",
            "match_url": "STREAMING_URL"
        }})}
</code>
</pre>

</div>
</div>
</div>
</section>  
            </div>
    )
};

export default Tvapi;