import React, { PureComponent } from "react";


class Header extends PureComponent {
  constructor(props) {
    super(props);
    var accessToken = localStorage.getItem("token");
    this.state = {
    };

  }
  componentDidMount() {
  }
  render() {
    return (
      <div>
        <header className="header" id="header">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-1 col-sm-2">
      <div className="mm-toggle-wrap">
      <div className="mm-toggle"><i className="fa fa-align-justify"></i><span className="mm-label">Menu</span> </div>
      </div>
      
      <a className="header__block header__brand" href="#">
      <h1> <img src="images/logo.png" alt="API UI logo" /></h1>
      </a>
      
      </div>
      <div className="col-lg-11 col-sm-10 hidden-xs">
      <div className="header__nav">
      <div className="header__nav--left">
      <ul className="dx-nav-0 dx-nav-0-docs">
      <li className="dx-nav-0-item ">
      <a className="dx-nav-0-link" href="javascript:void(0);">All Space</a>
      </li>
      <li className="dx-nav-0-item dx-nav-active">
      <a className="dx-nav-0-link" href="javascript:void(0);">API Reference</a>
      </li>
      <li className="dx-nav-0-item ">
      <a className="dx-nav-0-link" href="javascript:void(0);">Support</a>
      </li>
      <li className="dx-nav-0-item ">
      <a className="dx-nav-0-link" target="new" href="https://documenter.getpostman.com/view/3630331/VVBQYpY8">Postman Collection</a>
      </li>
      </ul>
      <form className="header__search dx-form-search" id="siteSearch" name="search" method="get">
      <label className="sr-only" for="siteQ">Enter search term</label>
      <input className="dx-search-input" id="siteQ" name="q" type="search" value="" placeholder="Search" />
      <span className="button-search fa fa-search"></span>
      </form>
      </div> 
      <div className="header__nav--right">
      <ul className="dx-nav-0 dx-nav-0-tools">
      <li className="dx-nav-0-item ">
      <a className="dx-nav-0-link" href="javascript:void(0);">&nbsp;</a>
      </li>

      </ul>
      <div className="dx-auth-block">
      <div className="dx-auth-logged-out">
      <a className="dx-auth-login dx-btn dx-btn-primary" data-js="auth-btn" href="/logout" data-dxa="login,nav-click,nav-login">Logout</a>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </header>
      </div>
    );
  }
}
export default Header;
