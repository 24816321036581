/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Link,
    useMatch,
    useResolvedPath
  } from "react-router-dom";
function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
  
    return (
        <Link
          className={  match ? "nav-link current" : "nav-link" }
          to={to}
          {...props}
        >
          {children}
        </Link>
    );
  }
export default CustomLink;