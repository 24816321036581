import React from 'react';

const AntiFraud = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Anti-Fraud Check Request Requirements</h3>

<p>First of all, the operator independently checks the player.</p>

<p><strong>Operator actions:</strong></p>
<ul>
    <li>1.Request the player's documents (without this, it makes no sense to check the player for fraud)</li>
    <li>2.Check bets ("Player's games" report or "Bets" tab)</li>
    <li>3.Check player balance changes (in player account by clicking on balance)</li>
    <li>4.Check Player Transactions</li>
</ul>
<p><strong>What to look for when checking:</strong></p>
<ul>
<li>many/big wins in one provider within one session</li>
<li>the same over a long period of time (every day the player wins the same game or games from the same provider)
</li>
<li>big win for one bet</li>
<li>hung bets</li>
<li>doubts about the player's documents
</li>
<li>suspicious transactions (deposit from one payment, and withdrawal to another; the player made several deposits from different cards in a short period of time)
</li>
</ul>



<p>After self-checking the player, the operator provides information to the manager. Further, the manager himself checks the player according to the mechanism described above.
</p>

<p>And only after rechecking by the manager, the operator sends a request by mail to Antifraud dep ( antifraud@softswiss.com ).
</p>
<p>Email subject: project name - check the player (specify the player's id)
</p>


<p><strong>The letter itself must include:</strong></p>

<ul>
<li>link to player</li>
<li>link to the gameplay for suspicious bets (to the page with bets and / or a specific bet)
</li>
<li>suspicious transactions</li>
<li>reason for suspicion</li>
<li>additional information that will help in clarifying the situation
</li>
</ul> 
<p>Response time for requests to Antifraud dep. - up to 3 business days.</p>



</div>
</div>
</div>
</section>  
            </div>
    )
};

export default AntiFraud;