import React from 'react';

const AvailableLanguages = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" class="method">
<div class="method-area">
<div class="method-copy" style={{width:"100%"}}>
<div class="method-copy-padding">
<h3 style={{marginTop:"0px"}}>Available Languages</h3>
<p>We are supporting language English-en</p>

</div>
</div>
</div>
</section>  
            </div>
    )
};

export default AvailableLanguages;