import Header from "./components/Header/Header";
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from "./components/Header/Sidebar";

function Layout({ children }) {
  var accessToken = localStorage.getItem("api_access_token");
  if(accessToken == "" || accessToken == null) {
   return  <Navigate to={'/login'} />
  }
  return (
    <>
      <div id="page">
        <Header />
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
}

export default Layout;