import React from 'react';
import { Button, Card } from 'react-bootstrap';

const PageNotFound = () => {


    var imagePath = '/images/logo.png';
    return (
        <div class="content d-flex justify-content-center align-items-center">
            <div class="d-block">
                <div class="login-container">
                    <div class="card-body">
                        <div class="text-center mb-3">
                            <img src={imagePath} alt="heera" className="" />
                            <div style={{ textAlign: "center" }}>
                                <h3>Redirecting to home page....</h3    >
                                <a href="/" class="btn btn-primary mt-10">Go To Home</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PageNotFound;
