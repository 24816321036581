import React from 'react';

const APIEndpoint = () => {
    return (
        <div id="documenter_content" className="method-area-wrapper">
<section id="documenter-1" className="method">
<div className="method-area">
<div className="method-copy" style={{width:"100%"}}>
<div className="method-copy-padding">
<h3 style={{marginTop:"0px"}}>API Endpoints and IPs</h3>

<div className="table-wrap">
    <table className="table table-bordered wrapped confluenceTable">
        <tbody>
            <tr>
                <th scope="col" className="confluenceTh">Environment</th>
                <th scope="col" className="confluenceTh">Hosts for casinos</th>
                <th scope="col" className="confluenceTh">IPs to whitelist</th>
            </tr>
            <tr>
                <td className="confluenceTd">Production</td>
                <td className="confluenceTd">
                    <strong>
                        <span >
                            <a className="external-link" href="http://api.casinoland.live/" rel="nofollow">
                            http://api.casinoland.live/
                            </a>
                        </span>
                    </strong>
                </td>
                <td className="confluenceTd">
                    <pre className="code-java">
                    148.251.195.53
                    </pre>
                </td>
            </tr>
        </tbody>
    </table>
</div>

</div>
</div>
</div>
</section>  
            </div>
    )
};

export default APIEndpoint;