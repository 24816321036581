import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import "./LoginPage.css";
import { v4 as uuidv4 } from "uuid";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respStatus: "",
      respMessage: "",
      gotoindex: false,
      isLoading: false,
      error_message:""
    };
  }
  goToIndex = () => {
    if (this.state.gotoindex === true) {
      return <Navigate to="/" />;
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();

    if(this.state.email!=='casinoland' || this.state.password!=='casinoland@2444')
    {
      this.setState({error_message: 'Invalid Credentials'});
      return false;
    }

  
    this.setState({ isLoading: true });
    let loginData = uuidv4();
    localStorage.setItem("api_access_token",loginData);
    window.location.href="/";
  };
  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  responseHtml = () => {
    if (this.state.respStatus === false) {
      return <div className="alert alert-danger">{this.state.respMessage}</div>;
    } else if (this.state.respStatus === true) {
      return (
        <div className="alert alert-success">{this.state.respMessage}</div>
      );
    }
  };
  render() {
    var imagePath = "images/logo.png";
    let {error_message} =this.state;
    return (
      <div class="content d-flex justify-content-center align-items-center">
        <div class="d-block">
          <div class="login-container">
            <div class="card-body">
              <div class="text-center mb-3">
                <img src={imagePath} alt="" style={{ width: "100px" }} />
                <h4 class="mb-0 mt-3"><strong>LOGIN TO API DOCUMENTATION</strong></h4>
              </div>
              {error_message!=''?<div className="alert alert-danger">{error_message}</div>:""}
              <form
                className="form loginform mb-0"
                onSubmit={this.handleSubmit}
              >
                <div class="form-group form-group-feedback form-group-feedback-left">
                  <input
                    type="text"
                    name="email"
                    placeholder="Username"
                    onChange={this.handleChange}
                    value={this.state.email}
                    className="form-control"
                  />
                </div>
                <div class="form-group form-group-feedback form-group-feedback-left">
                  <input
                    type="password"
                    name="password"
                    id="inputPassword"
                    placeholder="Password"
                    onChange={this.handleChange}
                    value={this.state.password}
                    className="form-control"
                  />
                </div>
                {this.goToIndex()}
                {this.responseHtml()}
                <div class="form-group">
                  <button type="submit" className="dx-btn-primary-2">
                    Login{" "}
                    {this.state.isLoading ? (
                      <i
                        className="fa fa-spinner fa-spin fa-fw text-white"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i className=" ml-2 fas fa-sign-in-alt"></i>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="copy_right">© Powered By Casinoland</div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
